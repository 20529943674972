import * as React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Divider from '../components/sections/sectionDivider';
import HeadingSection from '../components/sections/headingSection';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <HeadingSection heading="404: Not Found" />
    <Divider />
    <div className="py-24 flex-center text-white bg-primary">
      <p className="text-xl">Oops, the page you are looking for doesn&apos;t exist.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
